@use "@angular/material" as mat;
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@include mat.core();

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "db-office";
  src: url("assets/dboff03.ttf");
}

$typography-config: mat.define-typography-config(
  $font-family: "db-office", 
);

@include mat.typography-hierarchy($typography-config);

$app-theme: mat.define-light-theme(
  (
    typography: $typography-config,
  )
);

@include mat.all-component-themes($app-theme);

html {
  position: relative;
  font-family: "db-office";
  overflow-x: hidden;
  height: 100%;
}

body {
  position: relative;
  background-image: url("assets/img/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  height: 100%;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.container {
  position: relative;
  text-align: center;
}

.center-align {
  text-align: center;
}

.login-container {
  position: relative;
  width: 350px;
  height: 450px;
  background-color: rgba(78, 54, 255, 0.9);
  text-align: center;
  margin: 0 auto;
}

.view-container {
  position: relative;
}

.fake-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}

.fake-link:hover {
  color: lightblue;
}

.password-container {
  position: absolute;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.98);
  min-height: 300px;
  top: calc(50% - 200px);
  left: calc(50% - 200px);
  padding: 20px;
  overflow: hidden;

  .animation-container {
    position: relative;
    animation: 0.2s ease-out 0s 1 slideInFromLeft;
    height: 230px;

    .form-container {
      position: relative;
      width: 100%;
      text-align: center;
      height: 200px;

      .form-submit {
        position: absolute;
        height: 30px;
        width: 100px;
        cursor: pointer;
        padding: 5px;
        border: none;
        background-color: rgb(6, 123, 219);
        color: white;
        bottom: 0;
        right: 5px;
      }

      .form-input {
        position: relative;
        min-height: 25px;
        min-width: 200px;
        width: 80%;
        padding: 3px;
        border: none;
        border-bottom: 1px solid gray;
        top: 30px;
        margin-bottom: 10px;
      }

      .form-input:focus {
        border-color: blue;
      }

      .form-submit:hover {
        background-color: rgb(3, 81, 145);
      }
    }

    .stamp {
      position: absolute;
      bottom: 0;
      width: 250px;
      font-size: 8px;
      margin-left: -10px;
    }
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(80%);
    }
    100% {
      transform: translateX(0);
    }
  }

  #logo {
    height: 80px;
  }

  #title {
    position: absolute;
    top: 0px;
    font-size: 24px;
    right: 20px;
  }
}

.container {
  position: relative;
  margin: auto;
}

.disabledRow * {
  background-color: grey;
}

.disabledRow:hover * {
  background-color: grey;
}

/*.maintable tbody:nth-child(odd) {background-color: #f2f2f2;}*/
.maintable tbody:nth-child(odd) {
  background-color: rgba(242, 242, 242, 0.95);
}
/*.maintable tbody:nth-child(even) {background-color: #ffffff;}*/
.maintable tbody:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.95);
}

.maintable tr:hover {
  background-color: #9e9e9e;
}

.maintable {
  border-collapse: collapse;
  width: 100%;
}

.maintable td,
.maintable th,
.maintable button {
  border: 1px solid #ddd;
  padding: 8px;
}

.maintable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
  background-color: rgba(38, 86, 241, 0.8);
  font-weight: normal;
}

.maintable button {
  width: 80%;
  height: 100%;
  min-width: 80px;
  background-color: rgba(0, 0, 0, 0);
  color: black;

  user-select: none;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none;
}

.maintable input {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  padding: 3px;
}

.maintable button:hover {
  background-color: #95d788;
}

.reject:hover button {
  background-color: red;
}

.maintable td:not(.btnholder) {
  text-align: left;
  cursor: pointer;
}

.maintable .btnholder {
  text-align: center;
}

.btnholder button {
  width: 100%;
}

.maintable textarea {
  width: 100%;
}

.password-container {
  position: absolute;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.98);
  min-height: 300px;
  top: calc(50% - 200px);
  left: calc(50% - 200px);
  padding: 20px;
  overflow: hidden;

  .animation-container {
    position: relative;
    animation: 0.2s ease-out 0s 1 slideInFromLeft;
    height: 230px;

    .form-container {
      position: relative;
      width: 100%;
      text-align: center;
      height: 200px;

      .form-submit {
        position: absolute;
        height: 30px;
        width: 100px;
        cursor: pointer;
        padding: 5px;
        border: none;
        background-color: rgb(6, 123, 219);
        color: white;
        bottom: 0;
        right: 5px;
      }

      .form-input {
        position: relative;
        min-height: 25px;
        min-width: 200px;
        width: 80%;
        padding: 3px;
        border: none;
        border-bottom: 1px solid gray;
        top: 30px;
        margin-bottom: 10px;
      }

      .form-input:focus {
        border-color: blue;
      }

      .form-submit:hover {
        background-color: rgb(3, 81, 145);
      }
    }

    .stamp {
      position: absolute;
      bottom: 0;
      width: 250px;
      font-size: 8px;
      margin-left: -10px;
    }
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(80%);
    }
    100% {
      transform: translateX(0);
    }
  }

  #logo {
    height: 80px;
  }

  #title {
    position: absolute;
    top: 0px;
    font-size: 24px;
    right: 20px;
  }
}

.container {
  position: relative;
  margin: auto;
  margin-bottom: 40px;
}

.disabledRow * {
  background-color: grey;
}

.disabledRow:hover * {
  background-color: grey;
}

/*.maintable tbody:nth-child(odd) {background-color: #f2f2f2;}*/
.maintable tbody:nth-child(odd) {
  background-color: rgba(242, 242, 242, 0.95);
}
/*.maintable tbody:nth-child(even) {background-color: #ffffff;}*/
.maintable tbody:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.95);
}

.maintable tr:hover {
  background-color: #9e9e9e;
}

.maintable {
  border-collapse: collapse;
  width: 100%;
}

.maintable td,
.maintable th,
.maintable button {
  border: 1px solid #ddd;
  padding: 8px;
}

.maintable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
  background-color: rgba(38, 86, 241, 0.8);
  font-weight: normal;
}

.maintable button {
  width: 80%;
  height: 100%;
  min-width: 80px;
  background-color: rgba(0, 0, 0, 0);
  color: black;

  user-select: none;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none;
}

.maintable input {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  padding: 3px;
}

.maintable button:hover {
  background-color: #95d788;
}

.reject:hover button {
  background-color: red;
}

.maintable td:not(.btnholder) {
  text-align: left;
  cursor: pointer;
}

.maintable .btnholder {
  text-align: center;
}

.btnholder button {
  width: 100%;
}

.maintable textarea {
  width: 100%;
}
